import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

import generic from '@nixweb/nixloc-ui/src/store/modules/generic'
import validation from '@nixweb/nixloc-ui/src/store/modules/validation'
import report from '@nixweb/nixloc-ui/src/store/modules/report'
import user from '@nixweb/nixloc-ui/src/store/modules/user'

import chat from './modules/chat'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage, paths: ["user"] })],
  modules: {
    generic, validation, user, report, chat
  }
})