import Vue from 'vue'
import Router from 'vue-router'

const Login = () => import('@/views/modules/adm/Login')
const Dashboard = () => import('@/views/modules/dashboard/Dashboard')

const UserList = () => import('@/views/modules/adm/UserList')
const UserCreateUpdate = () => import('@/views/modules/adm/UserCreateUpdate')

// charging
const BilletList = () => import('@/views/modules/billing/BilletList')
const ChargingList = () => import('@/views/modules/billing/ChargingList')
const ChargingCreateUpdate = () => import('@/views/modules/billing/ChargingCreateUpdate')

// crm
const LeadList = () => import('@/views/modules/crm/LeadList')
const LeadCreateUpdate = () => import('@/views/modules/crm/LeadCreateUpdate')
const CustomerList = () => import('@/views/modules/crm/CustomerList')
const CustomerCreateUpdate = () => import('@/views/modules/crm/CustomerCreateUpdate')
const ConfigCrm = () => import('@/views/modules/crm/ConfigCrm')
const CategoryCustomer = () => import('@/views/modules/crm/CategoryCustomer')


const SupportCompanyList = () => import('@/views/modules/support/CompanyList')
const SupportCompanyUpdate = () => import('@/views/modules/support/CompanyUpdate')
const SupportUserCreateUpdate = () => import('@/views/modules/support/UserCreateUpdate')
const ComunicationCreateUpdate = () => import('@/views/modules/support/ComunicationCreateUpdate')
const CourseList = () => import('@/views/modules/support/CourseList')

const Show = () => import('@/views/modules/shared/Show')
const Job = () => import('@/views/modules/shared/JobList')
const Calendar = () => import('@/views/modules/calendar/Calendar')

//chat
const Chat = () => import('@/views/modules/chat/Chat')

//planner
const Frame = () => import('@/views/modules/planner/Frame')
const Kanban = () => import('@/views/modules/planner/Kanban')
const ConfigPlanner = () => import('@/views/modules/planner/ConfigPlanner')
const Tag = () => import('@/views/modules/planner/Tag')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', redirect: { name: "login" } },
    { path: "/login", name: "login", component: Login },
    { path: "/dashboard", name: "dashboard", component: Dashboard },
    { path: "/adm/user/list", name: "userList", props: { module: "Adm" }, component: UserList },
    { path: "/adm/user", name: "userCreate", props: { module: "Adm" }, component: UserCreateUpdate },
    { path: "/adm/user/:id", name: "userUpdate", props: { module: "Adm" }, component: UserCreateUpdate },
    { path: "/billing/billet/list/:filter", name: "billetList", props: { module: "Billet" }, component: BilletList },
    { path: "/billing/charging/list", name: "chargingList", props: { module: "Billing" }, component: ChargingList },
    { path: "/billing/charging", name: "chargingCreate", props: { module: "Billing" }, component: ChargingCreateUpdate },
    { path: "/billing/charging/:id", name: "chargingUpdate", props: { module: "Billing" }, component: ChargingCreateUpdate },
    { path: "/crm/customer/list", name: "customerList", props: { module: "CRM" }, component: CustomerList },
    { path: "/crm/lead/list", name: "leadList", props: { module: "CRM" }, component: LeadList },
    { path: "/crm/customer", name: "customerCreate", props: { module: "CRM" }, component: CustomerCreateUpdate },
    { path: "/crm/customer/:id", name: "customerUpdate", props: { module: "CRM" }, component: CustomerCreateUpdate },
    { path: "/crm/lead", name: "leadCreate", props: { module: "CRM" }, component: LeadCreateUpdate },
    { path: "/crm/lead/:id", name: "leadUpdate", props: { module: "CRM" }, component: LeadCreateUpdate },
    { path: "/crm/config", name: "configCrm", props: { module: "CRM" }, component: ConfigCrm },
    { path: "/crm/category-customer", name: "categoryCustomer", props: { module: "CRM" }, component: CategoryCustomer },
    { path: "/planner/frame", name: "frameList", props: { module: "Planner" }, component: Frame },
    { path: "/planner/kanban/:id/:name", name: "kanbanList", props: { module: "Planner" }, component: Kanban },
    { path: "/planner/config", name: "plannerConfig", props: { module: "Planner" }, component: ConfigPlanner },
    { path: "/planner/tag", name: "tag", props: { module: "Planner" }, component: Tag },
    { path: "/support/company", name: "supportCompanyList", props: { module: "Support" }, component: SupportCompanyList },
    { path: "/support/company/:id", name: "supportCompanyUpdate", props: { module: "Support" }, component: SupportCompanyUpdate },
    { path: "/support/comunication", name: "comunicationCreateUpdate", props: { module: "Support" }, component: ComunicationCreateUpdate },
    { path: "/support/user/:id", name: "userSupportUpdate", props: { module: "Support" }, component: SupportUserCreateUpdate },
    { path: "/support/course", name: "courseList", props: { module: "Support" }, component: CourseList },
    { path: "/show", name: "show", component: Show },
    { path: "/job", name: "jobList", props: { module: "Job" }, component: Job },
    { path: "/job/:filter", name: "jobListFilter", props: { module: "Job" }, component: Job },
    { path: "/calendar", name: "calendar", props: { module: "Calendar" }, component: Calendar },
    { path: "/chat", name: "chat", props: { module: "Chat" }, component: Chat },
    { path: '*', component: Login }
  ],
})

export default router