export default {
    namespaced: true,
    state: {
        rooms: [],
        messageNotRead: false,
        leadQueue: [],
        schedule: [],
        usersFilter: [],
    },
    getters: {
        isRoomOpen: (state) => (roomId) => {
            var isExist = state.rooms.find(room => {
                return room.roomId === roomId
            })
            if (isExist) return true;
            return false;
        },
        isMessageNotRead: (state) => {
            var isExist = state.rooms.find(room => {
                return room.unreadCount === " "
            })
            if (isExist || state.messageNotRead) return true;
            return false;
        },
        usersFilter: (state) => {
            var ids = "";
            state.usersFilter.forEach(item => {
                ids += `${item.id},`;
            });
            return ids;
        },
    },
    mutations: {
        addRooms: (state, rooms) => {
            rooms.forEach(room => {
                const selected = state.rooms.find(x => x.roomId === room.roomId);
                if (selected != undefined) {
                    selected.order = room.order;
                }
            });

            rooms.forEach(newRoom => {
                const roomExists = state.rooms.some(x => x.roomId === newRoom.roomId);
                if (!roomExists) {
                    state.rooms.push(newRoom);
                }
            });

            state.rooms.sort((a, b) => a.order - b.order);
        },
        updateRooms: (state, rooms) => {
            state.rooms = [];
            state.rooms = rooms;
        },
        closeRoom: (state, roomId) => {
            let filter = state.rooms.filter(function (item) {
                return item.roomId != roomId;
            });
            state.rooms = filter;
        },
        cleanRooms: (state) => {
            state.rooms = [];
        },
        cleanStatusRoom: (state, roomId) => {
            state.rooms.forEach(room => {
                if (room.roomId === roomId) {
                    room.unreadCount = "";
                }
            });
        },
        markAsRead: (state, roomId) => {
            state.rooms.forEach(room => {
                if (room.roomId === roomId) {
                    room.unreadCount = "";
                }
            });
            var isExist = state.rooms.find(room => {
                return room.unreadCount === " "
            })
            if (!isExist) state.messageNotRead = false;
        },
        markAsNotRead: (state, roomId) => {
            if (roomId) {
                state.rooms.forEach(room => {
                    if (room.roomId === roomId) {
                        room.unreadCount = " ";
                    }
                });
            }
            state.messageNotRead = true;
        },
        updateLastMessage: (state, message) => {
            state.rooms.forEach(function (room) {
                if (room.roomId == message.conversationId) {
                    if (lastMessage == null) {
                        var lastMessage = {
                            content: message.content,
                            message: message.timestamp
                        }
                        room.lastMessage = lastMessage;
                    } else {
                        room.lastMessage.content = message.content;
                        room.lastMessage.timestamp = message.timestamp;
                    }
                }
            });
        },
        updateUserInRoom: (state, obj) => {
            state.rooms.forEach(function (room) {
                if (room.roomId == obj.roomId) {
                    room.avatar = `https://espaco.blob.core.windows.net/nixloc-photo-user/${obj.user.photo}`;
                    room.user = obj.user;
                }
            });
        },
        updateUsersFilter: (state, usersFilter) => {
            state.usersFilter = [];
            state.usersFilter = usersFilter;
        },

        addLeadQueue: (state, leadQueue) => {
            state.leadQueue = leadQueue;
        },
        addSchedule: (state, schedule) => {
            state.schedule = schedule;
        },
        cleanQueue: (state) => {
            state.leadQueue = [];
        },
        cleanSchedule: (state) => {
            state.schedule = [];
        },
    },
}