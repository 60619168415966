import Vue from 'vue'
import App from './App.vue'

import router from './config/router'
import store from './store/store'

import api from './config/axios'

//import * as Sentry from "@sentry/vue";
//import { BrowserTracing } from "@sentry/tracing";

import BootstrapVue from 'bootstrap-vue'
import VueLoading from "vue-loading-template";
import VueTheMask from 'vue-the-mask'
import VueMoment from 'vue-moment'
import Toasted from 'vue-toasted'
import JsonExcel from "vue-json-excel"
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueCurrencyFilter from 'vue-currency-filter'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'

const moment = require('moment')
require('moment/locale/pt-br')


Vue.use(BootstrapVue)
Vue.use(VueLoading)
Vue.use(VueTheMask)
Vue.use(VueMoment, { moment })
Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 3000,
})
Vue.component("downloadExcel", JsonExcel);
Vue.use(CKEditor);
Vue.use(VueCurrencyFilter,
  {
    symbol: 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })

Vue.prototype.$http = api;
Vue.config.productionTip = false



/*Sentry.init({
  Vue,
  dsn: "https://661af109257a4c14bb77b190167774d9@o1165997.ingest.sentry.io/6256211",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "https://nixloc-prod.azurewebsites.net", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});*/


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
