<template>
  <div id="app">
    <div v-if="logged">
      <Signalr />
      <Header backgroundColor="#EA7F29">
        <IconMolded :icons="icons" />
        <div @click="navegateTo('chat')">
          <div class="icon-chat">
            <i class="fa-solid fa-comment"></i>
          </div>

          <div v-if="isMessageNotRead" class="badge-message blink">
            <i class="fa-regular fa-circle-dot"></i>
          </div>
        </div>

        <Account :items="items" :exited="logout" />
      </Header>
      <Menu baseColor="#577696" urlImage="https://espaco.blob.core.windows.net/nixloc-assets/logo_icon_nixloc.png"
        :urlPhoto="urlBaseImage + (userLogged.user.photo ? userLogged.user.photo : 'noImageEdit.png')" />
      <Modal title="Tem certeza que deseja sair do sistema?" :width="500" :height="750" v-if="showModal('confirmLogout')">
        <div class="text-center">
          <br />
          <Button _key="btnConfirmLogout" type="edit" title="Cancelar" classIcon="fas fa-create" size="small"
            :clicked="logout" />
          <Button _key="btnConfirmLogout" type="danger" title="Sim, tenho" classIcon="fas fa-create" size="medium"
            :clicked="logout" />
        </div>
      </Modal>
      <Modal title="Perfil" :width="600" :height="750" v-if="showModal('profile')">
        <Profile v-if="showModal('profile')" />
      </Modal>
    </div>
    <div>
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>


import IconMolded from "@nixweb/nixloc-ui/src/component/layout/IconMolded.vue";
import Account from "@nixweb/nixloc-ui/src/component/layout/Account.vue";
import Header from "@nixweb/nixloc-ui/src/component/layout/Header.vue";
import Menu from "@nixweb/nixloc-ui/src/component/layout/Menu.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Signalr from './components/modules/signalr/Signalr.vue'
import Profile from './components/modules/adm/user/Profile.vue'

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "App",
  components: { Menu, Header, IconMolded, Account, Button, Modal, Signalr, Profile },
  data() {
    return {
      urlBaseImage: "https://espaco.blob.core.windows.net/nixloc-photo-user/",
      items: [],
      icons: [
        {
          tooltip: "Dashboard",
          eventName: "navegateToDashboard",
          icon: "fa-solid fa-chart-tree-map",
        },
        {
          tooltip: "Calendário",
          eventName: "navegateToCalendar",
          icon: "fa-solid fa-calendars",
          module: "Calendar",
        },
        {
          tooltip: "Tarefa",
          eventName: "navegateToJob",
          icon: "fa-solid fa-check-double",
          module: "Job",
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generic", ["event", "showModal"]),
    ...mapGetters("chat", ["isMessageNotRead"]),
    ...mapGetters("user", ["hasRule"]),
    logged() {
      if (this.userLogged.token) return true;
      return false;
    },

  },
  methods: {
    ...mapMutations("user", ["addUserLogged"]),
    ...mapMutations("generic", ["openModal"]),
    confirmLogout() {
      this.openModal("confirmLogout");
    },
    logout() {
      this.addUserLogged({});
      window.location.replace("/login");
    },
    navegateTo(routeName) {
      this.$router.push({
        name: routeName,
      });
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "navegateToDashboard")
          this.$router.push({
            name: "dashboard",
          });

        if (event.name == "navegateToCalendar")
          this.$router.push({
            name: "calendar",
          });

        if (event.name == "navegateToJob")
          this.$router.push({
            name: "jobList",
          });

        if (event.name == "clickedProfile")
          this.openModal("profile");
      },
      deep: true,
    },
  },
};
</script>
<style>
.panel {
  margin-top: 0px !important;
}

.icon-chat {
  margin-left: 155px !important;
  margin-top: -39px;
  font-size: 22px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

.badge-message {
  margin-left: 159px !important;
  margin-top: -36px;
  font-size: 20px;
  color: white;
  font-size: 13px;
  border-radius: 20px;
  color: red;
  cursor: pointer;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
}
</style>