<template>
  <div>
    <br>
  </div>
</template>
<script>

import * as signalR from "@microsoft/signalr";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "Signalr",
  components: {

  },
  data() {
    return {
      signalConnection: null,
      userName: "",
      companyId: 0,
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule"]),
  },
  mounted() {
    this.connectionBuilder();
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    ...mapMutations("chat", ["addMessage", "updateStatusMessage", "markAsNotRead", "closeRoom"]),
    connectionBuilder() {
      var url = process.env.VUE_APP_API_URL;

      this.userId = this.userLogged.user.id;
      this.userName = this.userLogged.user.userName;

      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`${url}signalr`)
        .build();

      this.connection.start().then(() => {
        this.addUserLogged();

        const hasCRMRule = this.hasRule('CRM');
        const hasFinanceRule = this.hasRule('Finance');
        const hasSupportRule = this.hasRule('Support');

        this.connection.on("NewMessageUserInternal", (message) => {
          this.addEvent({ name: "newMessageUserInternal", data: message });
        });

        this.connection.on("NewOrOpenConversation", (roomId) => {
          this.addEvent({ name: "conversationStarted", data: { id: roomId } });
        });

        this.connection.on("SendTemplateLeadQueue", (roomId) => {
          this.addEvent({ name: "getLeadQueue" });
        });

        this.connection.on("CreateNewLead", (response) => {
          if ((response.channel === 1 && hasCRMRule) || (response.channel === 3 && hasFinanceRule) || (response.channel === 2 && hasSupportRule)) {
            this.addEvent({ name: "createNewLead", data: response });
            this.markAsNotRead('');
          }
        });

        this.connection.on("NewMessageOrConversation", (response) => {
          if ((response.channel === 1 && hasCRMRule) || (response.channel === 3 && hasFinanceRule) || (response.channel === 2 && hasSupportRule)) {
            this.addEvent({ name: "newMessageOrConversation", data: response });
            this.markAsNotRead('');
          }
        });

        this.connection.on("UpdateStatusMessage", (message) => {
          this.addEvent({ name: "updateStatusMessage", data: message });
        });

        this.connection.on("CloseRoom", (roomId) => {
          this.closeRoom(roomId);
        });
      });

      this.connection.start().catch((err) => console.error(err));
    },
    addUserLogged() {
      this.connection.invoke("AddUserLogged", this.userId, this.userName);
    },
  },
  watch: {
    event: {
      handler(event) {

      },
      deep: true,
    },
  },
};
</script>
<style scopep>
.full-page {
  height: 100%;
  width: 100%;
  background-color: white;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 1500;
}

.n-div-center {
  margin: auto;
  width: 80%;
  height: 50%;
  padding: 50px;
}

.n-font-icone {
  font-size: 15px;
}

.icon-siren {
  font-size: 25px;
  color: orange;
}
</style>
