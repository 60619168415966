const dicas = [];

const manutencao = [
  // { formNome: "opcoesFaturamento", campo: "unificar", titulo: "Unificar Cliente", descricao: "Ao marcar essa opção" },

];


manutencao.forEach(function (obj) {
  dicas.push(obj);
});



export default dicas;