<template>
    <div class="main-profile">
        <div v-if="loading">
            <Loading :center="false" />
        </div>
        <div v-else>
            <div class="text-center">
                <ImageUpload title="Carregar Foto" container="nixloc-photo-user" classCss="img-round" :width="100"
                    :height="100" accepted=".jpg .png" urlPost="/api/v1/adm/file-upload/upload"
                    urlRemove="/api/v1/adm/file-upload/delete" :onLoad="_updatePhoto" v-model="user.photo" />
            </div>
            <br>
            <b-tabs>
                <b-tab title="Nome">
                    <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="50"
                        v-model="user.name" />
                </b-tab>
                <b-tab title="Alterar Senha">
                    <InputPassword title="Senha" field="password" :formName="formName" :required="true" :maxLength="50"
                        v-model="user.password">
                    </InputPassword>
                    <InputPassword title="Confirme Senha" field="password" :formName="formName" :required="true"
                        :maxLength="50" v-model="user.confirmPassword">
                    </InputPassword>
                </b-tab>
            </b-tabs>
            <br>
            <b-row>
                <b-col sm="12">
                    <div class="text-right">
                        <Button _key="btnUpdateUser" type="success" title="Salvar"
                            :disabled="!isFormValid(formName) || user.password != user.confirmPassword"
                            classIcon="fas fa-save" size="medium" :clicked="update" />
                    </div>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>

import ImageUpload from "@nixweb/nixloc-ui/src/component/forms/ImageUpload";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import User from "@/components/modules/adm/user/User.js";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "Profile",
    components: {
        ImageUpload, InputText, InputPassword, Button, Loading
    },
    data() {
        return {
            formName: "profile",
            user: new User(),
            confirmPassword: "",
            urlUpdate: "/api/v1/adm/user/update",
            urlGetById: "/api/v1/adm/user/get-by-id",
            loading: true,
        };
    },
    mounted() {
        this.getById();
    },
    computed: {
        ...mapState("user", ["userLogged"]),
        ...mapGetters("validation", ["isFormValid"]),
    },
    methods: {
        ...mapActions("generic", ["putApi", "getApi"]),
        ...mapMutations("generic", ["hideModal", "removeLoading"]),
        ...mapMutations("user", ["updatePhoto"]),
        getById() {
            let params = { url: this.urlGetById, obj: { userId: this.userLogged.user.id } };
            this.getApi(params).then((response) => {
                if (response.success) {
                    this.user.update(response.content);
                    let self = this;
                    setTimeout(function () {
                        self.loading = false;
                    }, 100);
                }
            });
        },
        update() {
            let params = { url: this.urlUpdate, obj: this.user, notNotifyToast: true };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.hideModal();
                }
                this.removeLoading(["btnUpdateUser"]);
            });
        },
        _updatePhoto() {
            let params = { url: this.urlUpdate, obj: this.user, notNotifyToast: true };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.updatePhoto(this.user.photo);
                }
            });
        },
    }
}
</script>
<style scoped>
.main-profile {
    max-width: 800px;
    margin: auto;
}
</style>